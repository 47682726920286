import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Zuzanna Janczak" />
      <Layout>
        <SimpleBanner title="Zuzanna Janczak">
          <StaticImage
            className="banner__image"
            src="../../images/band/zuzanna.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Skrzypaczka, ukończyła z wyróżnieniem Akademię Muzyczną w Krakowie
            oraz Królewskie Konserwatorium Muzyczne w Brukseli. Koncertuje w
            Polsce, Europie i Stanach Zjednoczonych, laureatka wielu konkursów
            skrzypcowych w Polsce i Europie. Koncertowała jako solistka z
            Orkiestrą Symfoniczną Filharmonii Śląskiej, w Studio Koncertowym
            Polskiego Radia im. W. Lutosławskiego w Warszawie oraz podczas
            takich festiwali, jak: Internationales Violinfestival Junger Meister
            Pablo Casals Festival, Aurora Varfestival, Carloforte Music
            Festival, Lucerne Festival oraz Tauron Nowa Muzyka Katowice.
            Współpracowała z wieloma orkiestrami symfonicznymi, m.in.:
            Mannheimer Philharmoniker, Narodowa Orkiestra Symfoniczna Polskiego
            Radia w Katowicach, Lucerne Academy Orchestra, Orkiestra Miasta
            Tychy Aukso. Swoje umiejętności doskonaliła podczas międzynarodowych
            kursów mistrzowskich pod kierunkiem wybitnych profesorów światowej
            sławy, m.in. Grigory Zhislin, Yair Kless, oraz zespołów kameralnych:
            Shangai Quartet, Prazak Quartet i Artis Quartet. Od 2019 roku
            występuje jako solistka w amerykańskich produkcjach muzycznych
            współpracując z agencją Barry Ball Artists z Florydy. Współpracuje i
            koncertuje z takimi artystami, jak : Urszula, Uriel Herman, Jef
            Neve, Piotr Steczek, Sebastian Riedel. Skrzypaczka bierze udział w
            wielu projektach, m.in. String Reactor i Muzyczna Podróż Dookoła
            Świata. Stypendystka Prezydenta Miasta Sosnowiec oraz dwukrotna
            stypendystka Ministra Kultury i Dziedzictwa Narodowego.
            Współpracując z Fundacją im. Jana Kiepury otrzymała stypendium
            Sosnowieckiego Programu Wspierania Inicjatyw Kulturalnych. W 2018
            roku otrzymała Stypendium Muzykogranty Instytucji im. K. Bochenek
            Miasto Ogrodów.
          </p>
          <p>
            Skrzypaczka gra zarówno na skrzypcach akustycznych i elektrycznych,
            posiada szeroki repertuar muzyki klasycznej, pop, rock,
            elektronicznej oraz world music. Swoją grę prezentowała podczas
            wielu pokazów i eventów, współpracując m.in. z: Dolce & Gabbana,
            Gucci, Oakley, Prada, Alain Mikli, Ray-Ban, Christian Dior.
          </p>
          <p>
            Uczestniczyła w wielu nagraniach studyjnych współtworząc takie
            albumy jak: <i>Urszula z kwartetem smyczkowym –Złote Przeboje
            Akustycznie, Soundtrack Uran Uran Wojciech Kucharczyk, Z tysiąca i
            jednej nocy wigilijnych- Joachim Sevenitz, Marta Król i Paweł
            Tomaszewski.</i>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
